import React from 'react';

import LandingPage from '../components/LandingPage/LandingPage';

const Home = () => {
  return (
    <LandingPage 
      pageType="investor"
      seo="Investors"
      image={require("../images/meta-preview-investors.jpg")}
      headerButtonLabel="Startups"
      headerButtonLink="/"
      heroTitle="Discover interesting startups quickly"
      heroCtaLabel="Start browsing startups"
      heroCtaLink="/"
      heroImg={require("../images/investor-preview-1.jpg")}
      SectionOneImg={require("../images/company-actions.png")}
      SectionOneTitle="First impressions count"
      SectionOneBody="As you browse through startups, you can mark a startup as interesting for you to come back to later, or send a message right away to get the conversation started."
      SectionTwoImg={require("../images/conversation-investors.png")}
      SectionTwoTitle="Start the conversation on your terms"
      SectionTwoBody="After an declaring an interest in a startup, you can chat and share files to relevant documents. Investors make the first move so you’ll never be bombarded by startups you are not interested in."
      endTitle="Discover interesting startups quickly"
      endCtaLabel="Browse startups"
      endCtaLink="/"
    />
  )
}

export default Home;

